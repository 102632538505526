<template>
  <div class="hello">
    {{ type }}
    <button @click="handleClick">button</button>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {},
  data() {
    this.name = "";
    return {
      // name: "",

      info: {},
      // info: {
      //   number: undefined,
      // },

      list: [],

      b: "",
    };
  },

  methods: {
    handleNameChange() {
      this.name = "vue" + Date.now();
      // 这时组件状态 this.name 发生了变化，但是并没有触发子组件更新
      // name 没有做响应式处理，所以不会触发子组件更新
      // 把 name 放到 return 中，就变成了响应式了
    },
    handleInfoChange() {
      this.info.number = 1;
      // 这时组件状态 this.info 发生了变化，但是并没有触发子组件更新
      // 响应式处理只存在于 info 上，info 下边的 number 属性没有做响应式处理，所以不会触发子组件更新
      // 在 info 中显式的声明了 number 属性，就会变成响应式了
    },
    handleListChange() {
      this.list.push(1, 2, 3);
      // 这时组件状态 this.list 并没有发生变化，但是触发了子组件更新
    },
    hanedleBChange() {
      this.b = "b";
      // 这时做了响应式处理，但是并没有触发子组件更新，因为这时模板中并没有使用到 b
    },
  },
};
</script>

<style scoped>
</style>
